import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"d-flex align-center",attrs:{"text":"","color":"primary","dark":"","small":""}},'v-btn',attrs,false),on),[(_vm.country)?_c('span',{staticClass:"d-flex align-center flex-gap-8"},[_c('span',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.country.emoji))]),_c('span',{},[_vm._v(_vm._s(_vm.country.name))])]):_c(VIcon,[_vm._v("flag")])],1)]}}]),model:{value:(_vm.showPrefixes),callback:function ($$v) {_vm.showPrefixes=$$v},expression:"showPrefixes"}},[_c(VCard,[_c(VCardText,[_c('country-field',{staticClass:"ma-4",attrs:{"items":_vm.allowedCountries,"item-text":"text"},on:{"onSelected":_vm.closeMenu},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [(typeof item !== 'string')?_c('div',{staticClass:"d-flex flex-gap-8"},[_c('span',[_vm._v(_vm._s(item.emoji))]),_c('span',[_vm._v(_vm._s(item.name))]),_c('span',[_vm._v("(+"+_vm._s(item.dialling.prefix)+")")])]):_c('span',[_vm._v(_vm._s(item))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-gap-8"},[_c('span',[_vm._v(_vm._s(item.emoji))]),_c('span',[_vm._v(_vm._s(item.name))]),_c('span',[_vm._v("(+"+_vm._s(item.dialling.prefix)+")")])])]}}]),model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }